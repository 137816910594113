import React from 'react';
import { useTranslations } from 'next-intl';
import { Link } from 'utils/navigation';
import ChannelAvatar from 'components/Avatar/Channel';
import Typography from 'components/Typography';
import Tag from 'components/Tag';
import { getPopularChannels } from 'serverActions/application';
import styles from './ChannelCard.module.scss';
interface ChannelCardProps {
  channel: Awaited<ReturnType<typeof getPopularChannels>>['channels'][0];
}
const ChannelCard: React.FC<ChannelCardProps> = ({
  channel
}) => {
  const t = useTranslations();
  return <Link href={{
    pathname: '/channel/[id]',
    params: {
      id: channel.id
    }
  }} className={styles.root} data-sentry-element="Link" data-sentry-component="ChannelCard" data-sentry-source-file="ChannelCard.tsx">
      <ChannelAvatar size="48" name={channel.title} src={channel?.profileImage?.url} alt={`Channel ${channel.title} avatar`} verified={!!channel.isVerified} data-sentry-element="ChannelAvatar" data-sentry-source-file="ChannelCard.tsx" />
      <Typography variant="h10" color="primary" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="ChannelCard.tsx">
        {channel.title}
      </Typography>
      {(channel?.category || []).map(category =>
    // @ts-ignore
    <Tag key={category.id} className={styles.tag}>
          {/* @ts-ignore */}
          {t(`categories.${category.id}`)}
        </Tag>)}
    </Link>;
};
export default ChannelCard;