import React, { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';
import Tab from 'components/Tab';
import { getChannelsFilters } from 'serverActions/application';
import Placeholder from '../FilterPlaceholder';
interface SubscribersProps {
  activeSubscribers: string;
  setActiveSubscribers: (subscribers: string) => void;
}
const subscribersFilters = [{
  title: 'Підписників',
  dataLinkAttribute: []
}, {
  title: '0-1K',
  dataLinkAttribute: [0, 1000]
}, {
  title: '1K-10K',
  dataLinkAttribute: [1000, 10000]
}, {
  title: '10K-100K',
  dataLinkAttribute: [10000, 100000]
}, {
  title: '100K-1M',
  dataLinkAttribute: [100000, 1000000]
}, {
  title: '1M-10M',
  dataLinkAttribute: [1000000, 10000000]
}, {
  title: '10M+',
  dataLinkAttribute: [10000000]
}];
const Subscribers: React.FC<SubscribersProps> = ({
  activeSubscribers,
  setActiveSubscribers
}) => {
  const t = useTranslations();
  const {
    data: channelsFilters,
    isLoading: isLoadingFilters
  } = useSWR('channelsFilters', getChannelsFilters, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const subscribersFilter = useMemo(() => {
    const maxSubscribers = channelsFilters?.members?.end || 0;
    const refilled = subscribersFilters.map((filter, index) => {
      if (index === 0) {
        return {
          ...filter,
          title: t('Header.search.full.subscribers.all')
        };
      }
      if (filter.title === '10M+') {
        return {
          ...filter,
          dataLinkAttribute: [...(filter.dataLinkAttribute as number[]), maxSubscribers]
        };
      }
      return filter;
    });
    const lastIndex = refilled.findIndex(filter => {
      if (typeof filter.dataLinkAttribute === 'string') {
        return false;
      }
      const [, upperBound] = filter.dataLinkAttribute;
      return upperBound >= maxSubscribers;
    });
    return refilled.slice(0, lastIndex + 1).map(filter => ({
      ...filter,
      dataLinkAttribute: filter.dataLinkAttribute.toString()
    }));
  }, [channelsFilters]);
  return <div data-sentry-component="Subscribers" data-sentry-source-file="Subscribers.tsx">
      {isLoadingFilters ? <Placeholder /> : <Tab variant="secondary" activeLink={activeSubscribers} links={subscribersFilter} onChange={setActiveSubscribers} />}
    </div>;
};
export default Subscribers;