import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslations } from 'next-intl';
import Typography from 'components/Typography';
import ChannelAvatar from 'components/Avatar/Channel';
import Tag from 'components/Tag';
import Button from 'components/Button';
import { Link } from 'utils/navigation';
import { ReactComponent as RecentIcon } from 'public/assets/icons/recent.svg';
import { getHistory, THistory } from '../history';
import PopularChannels from '../PopularChannels';
import styles from './ChannelsHistory.module.scss';
const ChannelsHistory: React.FC = () => {
  const t = useTranslations();
  const [searchHistory, setSearchHistory] = useState<THistory>({});
  const handleClearHistory = () => {
    const history = JSON.parse(localStorage.getItem('searchHistory') || '{}') as THistory;
    localStorage.setItem('searchHistory', JSON.stringify({
      ...history,
      channels: []
    }));
    setSearchHistory({});
  };
  useEffect(() => {
    setSearchHistory(getHistory());
  }, []);
  return <div className={styles.root} data-sentry-component="ChannelsHistory" data-sentry-source-file="ChannelsHistory.tsx">
      <div className={styles.indents}>
        <Typography component="p" variant="h10" className={cx(styles.sectionTitle, styles.history)} data-sentry-element="Typography" data-sentry-source-file="ChannelsHistory.tsx">
          {t('Header.search.full.recent')}
          {!!searchHistory?.channels?.length && <Button size="small" variant="secondary" onClick={handleClearHistory}>
              {t('Header.search.full.clear')}
            </Button>}
        </Typography>
        {searchHistory?.channels?.map(history => history.type === 'keyword' ? <Link key={history.keyword} href={{
        pathname: '/channels',
        query: {
          search: history.keyword
        }
      }} className={styles.keyword}>
              <div className={styles.rounded}>
                <RecentIcon />
              </div>
              <Typography variant="h9bk" color="primary">
                {history.keyword}
              </Typography>
            </Link> : <Link key={history.id} href={{
        pathname: '/channel/[id]',
        params: {
          id: history.id
        }
      }} className={styles.channel}>
              <ChannelAvatar size="32" name={history.title} src={history?.avatar} alt={`Channel ${history.title} avatar`} verified={!!history.isVerified} />
              <Typography variant="h9bk" color="primary">
                {history.title}
              </Typography>
              <div>
                {(history?.category || []).map(category =>
          // @ts-ignore
          <Tag key={category.id}>{t(`categories.${category.id}`)}</Tag>)}
              </div>
            </Link>)}
        {!searchHistory?.channels?.length && <div className={styles.empty}>
            <Typography variant="h6sb">{t('Header.search.full.channels.empty')}</Typography>
          </div>}
        <Typography component="p" variant="h10" className={styles.sectionTitle} data-sentry-element="Typography" data-sentry-source-file="ChannelsHistory.tsx">
          {t('Header.search.full.popular')}
        </Typography>
      </div>
      <PopularChannels data-sentry-element="PopularChannels" data-sentry-source-file="ChannelsHistory.tsx" />
    </div>;
};
export default ChannelsHistory;