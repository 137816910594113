import React, { useContext, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import InfiniteScroller from 'components/InfiniteScroller';
import { getPopularChannels } from 'serverActions/application';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import ChannelCard from './ChannelCard';
import Placeholder from './ChannelPlaceholder';
import styles from './PopularChannels.module.scss';
const PopularPosts: React.FC = () => {
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const {
    data,
    isValidating: isLoadingPopularChannels,
    size,
    setSize
  } = useSWRInfinite(index => ({
    page: index + 1,
    location: countriesFilter,
    entity: 'search-popular-channels'
  }), ({
    page,
    location
  }) => getPopularChannels(page, location), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const {
    channels,
    total
  } = useMemo(() => {
    if (data) {
      return {
        channels: data.flatMap(page => {
          return page ? page.channels : [];
        }),
        total: data[0]?.total || 0
      };
    }
    return {
      channels: [],
      total: 0
    };
  }, [data]);
  const loadNextPage = () => {
    if (!isLoadingPopularChannels) {
      setSize(size + 1);
    }
  };
  return <div className={styles.root} data-sentry-component="PopularPosts" data-sentry-source-file="PopularChannels.tsx">
      <InfiniteScroller isLoading={isLoadingPopularChannels} direction="horizontal" items={channels} component={ChannelCard} uniqItemKey="id" loadNext={loadNextPage} total={total} placeholder={Placeholder} itemAsProps="channel" classes={{
      item: styles.item
    }} data-sentry-element="InfiniteScroller" data-sentry-source-file="PopularChannels.tsx" />
    </div>;
};
export default PopularPosts;