import React from 'react';
import useSWR from 'swr';
import { useTranslations } from 'next-intl';
import Tab from 'components/Tab';
import { getTopics } from 'serverActions/home';
import type { TTopic } from 'business/application/repository/types.d';
import Placeholder from '../FilterPlaceholder';
interface TopicsProps {
  activeCategory: string;
  setActiveCategory: (category: string) => void;
}
const Topics: React.FC<TopicsProps> = ({
  activeCategory,
  setActiveCategory
}) => {
  const t = useTranslations();
  const {
    data: categories,
    isLoading: isLoadingCategories
  } = useSWR('categories', getTopics, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const categoriesList: {
    title: string;
    dataLinkAttribute: string | null;
  }[] = [{
    title: t('Header.search.full.topics.all'),
    dataLinkAttribute: ''
  }, ...((categories || []) as TTopic[]).map(category => ({
    // @ts-ignore
    title: t(`categories.${category.id}`),
    dataLinkAttribute: category.id
  }))];
  return <div data-sentry-component="Topics" data-sentry-source-file="Topics.tsx">
      {categoriesList.length > 1 && <Tab withOverflow keepVisible={7} variant="secondary" activeLink={activeCategory} links={categoriesList} onChange={setActiveCategory} />}
      {isLoadingCategories && <Placeholder />}
    </div>;
};
export default Topics;