import React, { ChangeEventHandler, useState } from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { Field } from 'react-final-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import cx from 'classnames';
// @ts-ignore
import shortNumber from 'short-number';
import Typography from 'components/Typography';
import Checkbox from 'components/Input/Checkbox';
import RawTextInput from 'components/Input/Text/RawTextInput';
import Divider from 'components/Divider';
import { TCountry } from 'business/application/repository/types.d';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import by from 'public/assets/flags/by.png';
import cn from 'public/assets/flags/cn.png';
import et from 'public/assets/flags/et.png';
import global from 'public/assets/flags/global.png';
import ind from 'public/assets/flags/in.png';
import ir from 'public/assets/flags/ir.png';
import kaz from 'public/assets/flags/kaz.png';
import kg from 'public/assets/flags/kg.png';
import ru from 'public/assets/flags/ru.png';
import uk from 'public/assets/flags/uk.png';
import uz from 'public/assets/flags/uz.png';
import styles from './CountriesList.module.scss';
interface CountriesListProps {
  isLoading: boolean;
  countries: TCountry[];
}
const flagsMap = {
  by,
  cn,
  et,
  global,
  in: ind,
  ir,
  kaz,
  kg,
  ru,
  uk,
  uz
};
const getFlag = (country: string | null) => {
  return flagsMap[country as keyof typeof flagsMap] ? flagsMap[country as keyof typeof flagsMap] : flagsMap.global;
};
const CountriesList: React.FC<CountriesListProps> = ({
  isLoading,
  countries
}) => {
  const t = useTranslations();
  const [filtered, setFiltered] = useState(countries);
  const countriesWithTranslation = countries?.map(c => ({
    ...c,
    // @ts-ignore
    title: t(`country.${c.id}`)
  })) || [];
  const handleChangeSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const {
      value
    } = e.target as HTMLInputElement;
    setFiltered(countriesWithTranslation.filter(({
      title
    }) => title.toLowerCase().includes(value.toLowerCase())));
  };
  if (isLoading) {
    return <div className={cx(styles.placeholder, styles.list)}>
        <div className={styles.item}>
          <div className={styles.checkbox} />
          <div className={styles.title} />
        </div>
        <div className={styles.item}>
          <div className={styles.checkbox} />
          <div className={styles.title} />
        </div>
        <div className={styles.item}>
          <div className={styles.checkbox} />
          <div className={styles.title} />
        </div>
        <div className={styles.item}>
          <div className={styles.checkbox} />
          <div className={styles.title} />
        </div>
        <div className={styles.item}>
          <div className={styles.checkbox} />
          <div className={styles.title} />
        </div>
      </div>;
  }
  return <div className={styles.list} data-sentry-component="CountriesList" data-sentry-source-file="CountriesList.tsx">
      <RawTextInput onChange={handleChangeSearch} classes={{
      root: styles.search
    }} InputProps={{
      classes: {
        input: styles.searchInput
      },
      startAdornment: <InputAdornment position="start" disablePointerEvents={false} className={styles.adornment}>
              <SearchIcon className={styles.searchIcon} />
            </InputAdornment>
    }} placeholder={t('filters.countries.search')} data-sentry-element="RawTextInput" data-sentry-source-file="CountriesList.tsx" />
      <Divider className={styles.divider} data-sentry-element="Divider" data-sentry-source-file="CountriesList.tsx" />
      {filtered?.map(country => <Field name="location" type="checkbox" value={country.id} key={country.id}>
          {inputProps => <FormControlLabel control={<div className={styles.iconWithInput}>
                  <Image src={getFlag(country.id)} alt="country flag" width={48} height={48} className={styles.icon} />
                  <Checkbox {...inputProps} className={styles.input} />
                </div>} classes={{
        root: cx(styles.checkbox, {
          [styles.checked]: inputProps.input.checked
        })
      }} label={<span className={styles.name}>
                  {/* @ts-ignore */}
                  {t(`country.${country.id}`)}{' '}
                  <span className={styles.count}>{shortNumber(country.channelsCount ?? 0)}</span>
                </span>} slotProps={{
        typography: {
          variant: 'h10bk',
          className: styles.label
        }
      }} />}
        </Field>)}
      {filtered.length === 0 && <Typography variant="h10bk" color="secondary" className={styles.notFound}>
          {t('noData')}
        </Typography>}
    </div>;
};
export default CountriesList;