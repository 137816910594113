import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import cx from 'classnames';
import { useTranslations } from 'next-intl';
import Paper from 'components/Paper';
import Tab from 'components/Tab';
import SearchInput from 'components/Input/Search/Search';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { useRouter } from 'utils/navigation';
import { saveSearchHistory } from 'features/Search/history';
import { ReactComponent as SearchInputIcon } from 'public/assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'public/assets/icons/close.svg';
import Country from 'features/Filters/Country';
import ChannelsResults from './ChannelsResults';
import ChannelsHistory from './ChannelsHistory';
import PostsResults from './PostsResults';
import PostsHistory from './PostsHistory';
import TopicsFilter from './Filters/Topics';
import SubscribersFilter from './Filters/Subscribers';
import ViewsFilter from './Filters/Views';
import styles from './Search.module.scss';
interface SearchProps {
  minimized?: boolean;
  animated?: boolean;
  className?: string;
  close?: () => void;
}
const getComponentByCondition = (isSearchActive: boolean, isChannelsTab: boolean) => {
  if (isSearchActive) {
    return isChannelsTab ? ChannelsResults : PostsResults;
  }
  return isChannelsTab ? ChannelsHistory : PostsHistory;
};
const Search: React.FC<SearchProps> = ({
  animated = true,
  minimized = false,
  className,
  close
}) => {
  const t = useTranslations();
  const router = useRouter();
  const [activeEntity, setActiveEntity] = useState<'channels' | 'posts'>('channels');
  const [activeCategory, setActiveCategory] = useState('');
  const [activeSubscribers, setActiveSubscribers] = useState('');
  const [activeViews, setActiveViews] = useState('');
  const entityLinks = [{
    title: t('Header.search.full.channels.title'),
    dataLinkAttribute: 'channels'
  }, {
    title: t('Header.search.full.posts.title'),
    dataLinkAttribute: 'posts'
  }];
  const handleSubmit = (values: {
    keyword: string;
  }) => {
    if (!values.keyword) return;
    saveSearchHistory(values.keyword, activeEntity);
    close?.();
    router.push({
      pathname: activeEntity === 'channels' ? '/channels' : '/posts',
      query: {
        search: values.keyword,
        ...(activeCategory && {
          categories: activeCategory
        }),
        ...(activeEntity === 'channels' ? {
          ...(activeSubscribers && {
            members: activeSubscribers
          })
        } : {
          ...(activeViews && {
            views: activeViews
          })
        })
      }
    });
  };
  return <Form onSubmit={handleSubmit} subscription={{
    submitting: true
  }} data-sentry-element="Form" data-sentry-component="Search" data-sentry-source-file="Search.tsx">
      {({
      handleSubmit
    }) => <div className={cx(styles.root, className)}>
          <form onSubmit={handleSubmit}>
            <Paper className={cx(styles.main, {
          [styles.animated]: animated
        })}>
              <div className={styles.header}>
                <Tab className={styles.tabs} variant="tertiary" activeLink={activeEntity} onChange={setActiveEntity} links={entityLinks} />
                {close && <Button rounded variant="text" size="large" className={styles.close} onClick={close}>
                    <CloseIcon />
                  </Button>}
              </div>
              <div className={styles.search}>
                <div className={styles.wrapper}>
                  <Field name="keyword" type="text" autoComplete="off" defaultValue="" placeholder={t(`Header.search.full.${activeEntity}.placeholder`)} component={SearchInput} classes={{
                root: styles.inputWrapper,
                input: styles.input
              }} InputProps={{
                ...(!minimized && {
                  autoFocus: true
                }),
                startAdornment: null
              }} />
                  <Country onlyStore dropdownProps={{
                className: styles.countries,
                withoutBorder: true
              }} />
                </div>
                <Button variant="primary" rounded size="large" type="submit" className={styles.buttonDesktop}>
                  <SearchInputIcon />
                </Button>
                <Button variant="primary" size="large" type="submit" className={styles.buttonMobile}>
                  <Typography variant="h10bk" color="white">
                    {t('Header.search.full.submit')}
                  </Typography>
                </Button>
              </div>
            </Paper>
            {!minimized && <Paper className={cx(styles.result, {
          [styles.animated]: animated
        })}>
                <div className={styles.content}>
                  <div className={styles.filters}>
                    <TopicsFilter activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
                    {activeEntity === 'channels' ? <SubscribersFilter activeSubscribers={activeSubscribers} setActiveSubscribers={setActiveSubscribers} /> : <ViewsFilter activeViews={activeViews} setActiveViews={setActiveViews} />}
                  </div>
                  <FormSpy subscription={{
              values: true
            }}>
                    {({
                values
              }) => {
                const isSearchActive = (values?.keyword || '').length >= 2;
                const isChannelsTab = activeEntity === 'channels';
                const SearchComponent = getComponentByCondition(isSearchActive, isChannelsTab);
                return <SearchComponent search={values.keyword} category={activeCategory} subscribers={activeSubscribers} views={activeViews} />;
              }}
                  </FormSpy>
                </div>
              </Paper>}
          </form>
        </div>}
    </Form>;
};
export default Search;