import React, { ComponentProps, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { Form, FormSpy } from 'react-final-form';
import { useTranslations } from 'next-intl';
import { OnChange } from 'react-final-form-listeners';
import Image from 'next/image';
import cx from 'classnames';
import Typography from 'components/Typography';
import useFilters from 'hooks/useFilters';
import DropDownButton from 'components/Button/Dropdown/Dropdown';
import { TransitionContext } from 'contexts/TransitionContext';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { getCountries } from 'serverActions/application';
import { TCountry } from 'business/application/repository/types.d';
import by from 'public/assets/flags/by.png';
import cn from 'public/assets/flags/cn.png';
import et from 'public/assets/flags/et.png';
import global from 'public/assets/flags/global.png';
import ind from 'public/assets/flags/in.png';
import ir from 'public/assets/flags/ir.png';
import kaz from 'public/assets/flags/kaz.png';
import kg from 'public/assets/flags/kg.png';
import ru from 'public/assets/flags/ru.png';
import uk from 'public/assets/flags/uk.png';
import uz from 'public/assets/flags/uz.png';
import CountriesList from './CountriesList';
import styles from './Country.module.scss';
interface CountryFiltersProps {
  onlyStore?: boolean;
  dropdownProps?: Omit<ComponentProps<typeof DropDownButton>, 'dropdown'>;
  override?: (string | null)[];
}
const COUNTRY_FIELD_NAME = 'location';
const flagsMap = {
  by,
  cn,
  et,
  global,
  in: ind,
  ir,
  kaz,
  kg,
  ru,
  uk,
  uz
};
const getFlag = (country: (string | null)[]) => {
  if (country.length === 1) {
    const singleCountry = country.at(0);
    return flagsMap[singleCountry as keyof typeof flagsMap] ? flagsMap[singleCountry as keyof typeof flagsMap] : flagsMap.global;
  }
  return flagsMap.global;
};
const CountryFilters: React.FC<CountryFiltersProps> = ({
  onlyStore = false,
  dropdownProps = {},
  override
}) => {
  const t = useTranslations();
  const {
    startTransition
  } = useContext(TransitionContext);
  const {
    country,
    setCountry
  } = useContext(ConfigurationContext);
  const {
    filters,
    storeFilters
  } = useFilters();
  const {
    data: countries,
    isLoading
  } = useSWR('/filters/countries', getCountries);
  const getTitle = (selectedCountries: (string | null)[]) => {
    const selectedCountriesTitles = selectedCountries.map(country =>
    // @ts-ignore
    t(`country.inPrefix.${country}`));
    return selectedCountriesTitles.length > 2 ? `${selectedCountriesTitles.slice(0, 2).join(', ')}, +${selectedCountriesTitles.length - 2}` : selectedCountriesTitles.join(', ');
  };
  const handleChange = ({
    values
  }: {
    values: Record<string, string[]>;
  }) => {
    if (onlyStore) {
      return;
    }
    const value = values.location.join(',');
    if ((filters.get(COUNTRY_FIELD_NAME) || '') === value) {
      return;
    }
    requestAnimationFrame(() => {
      startTransition(() => {
        storeFilters([{
          name: COUNTRY_FIELD_NAME,
          value
        }, {
          name: 'regions',
          value: ''
        }]);
      });
    });
  };
  const initialValues = useMemo(() => ({
    location: override || country
  }), []);
  return <Form<{
    location: (string | null)[];
  }> onSubmit={() => {}} initialValues={initialValues} subscription={{
    submitting: true
  }} data-sentry-element="Form" data-sentry-component="CountryFilters" data-sentry-source-file="Country.tsx">
      {({
      form
    }) => <>
          <OnChange name={COUNTRY_FIELD_NAME}>
            {(value: (string | null)[]) => {
          let newValue = value;
          if (value.includes(null) && value.length > 1) {
            if (value.findIndex(v => v === null) === 0) {
              newValue = value.filter(v => v !== null);
            } else {
              newValue = [null];
            }
            form.change('location', newValue);
          }
          if (newValue.length === 0) {
            newValue = [null];
            form.change('location', newValue);
          }
          setCountry(newValue);
        }}
          </OnChange>
          <DropDownButton withArrow dropdown={<CountriesList isLoading={isLoading} countries={countries as TCountry[]} />} {...dropdownProps}>
            {isLoading ? <div className={cx(styles.placeholder, styles.item)}>
                <div className={styles.icon} />
                <div className={styles.title} />
              </div> : <>
                <FormSpy<{
            location: (string | null)[];
          }> subscription={{
            values: true,
            initialValues: true
          }}>
                  {({
              values
            }) => {
              if (values.location.length === 0) {
                return <Typography variant="h10bk">{t('filters.countries.title')}</Typography>;
              }
              return <div className={styles.item}>
                        <Image src={getFlag(values.location)} alt={`${getTitle(values.location)} country flag`} width={48} height={48} className={styles.icon} />
                        <Typography variant="h10bk" className={styles.title}>
                          {!values.location.every(c => c === null) && t('country.inPrefix.prefix')}{' '}
                          {getTitle(values.location)}
                        </Typography>
                      </div>;
            }}
                </FormSpy>
                {!onlyStore && <FormSpy subscription={{
            values: true
          }} onChange={handleChange} />}
              </>}
          </DropDownButton>
        </>}
    </Form>;
};
export default CountryFilters;