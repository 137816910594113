import React, { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';
import Tab from 'components/Tab';
import { getPostsFilters } from 'serverActions/application';
import Placeholder from '../FilterPlaceholder';
interface ViewsProps {
  activeViews: string;
  setActiveViews: (views: string) => void;
}
const viewsFilters = [{
  title: 'Переглядів',
  dataLinkAttribute: []
}, {
  title: '0-10K',
  dataLinkAttribute: [0, 10000]
}, {
  title: '10K-500K',
  dataLinkAttribute: [10000, 500000]
}, {
  title: '500K-1M',
  dataLinkAttribute: [500000, 1000000]
}, {
  title: '1M-10M',
  dataLinkAttribute: [1000000, 10000000]
}, {
  title: '10M-50M',
  dataLinkAttribute: [10000000, 50000000]
}, {
  title: '50M+',
  dataLinkAttribute: [50000000]
}];
const Views: React.FC<ViewsProps> = ({
  activeViews,
  setActiveViews
}) => {
  const t = useTranslations();
  const {
    data: postsFilters,
    isLoading: isLoadingPostsFilters
  } = useSWR('postsFilters', getPostsFilters, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const viewsFilter = useMemo(() => {
    const maxViews = postsFilters?.views?.end || 0;
    const refilled = viewsFilters.map((filter, index) => {
      if (index === 0) {
        return {
          ...filter,
          title: t('Header.search.full.views.all')
        };
      }
      if (filter.title === '50M+') {
        return {
          ...filter,
          dataLinkAttribute: [...(filter.dataLinkAttribute as number[]), maxViews]
        };
      }
      return filter;
    });
    const lastIndex = refilled.findIndex(filter => {
      if (typeof filter.dataLinkAttribute === 'string') {
        return false;
      }
      const [, upperBound] = filter.dataLinkAttribute;
      return upperBound >= maxViews;
    });
    return refilled.slice(0, lastIndex + 1).map(filter => ({
      ...filter,
      dataLinkAttribute: filter.dataLinkAttribute.toString()
    }));
  }, [postsFilters]);
  return <div data-sentry-component="Views" data-sentry-source-file="Views.tsx">
      {isLoadingPostsFilters ? <Placeholder /> : <Tab variant="secondary" activeLink={activeViews} links={viewsFilter} onChange={setActiveViews} />}
    </div>;
};
export default Views;