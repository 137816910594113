import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import useInfiniteScroll, { InfiniteScrollProps } from 'react-easy-infinite-scroll-hook';
import composeRefs, { updateRef } from 'utils/composeRef';
import styles from './InfiniteScroller.module.scss';
export type Scroller = {
  element?: RefObject<HTMLDivElement>;
  scrollNext: () => void;
  scrollBack: () => void;
};
type FlattenObjectKeys<T extends Record<string, unknown>, Key = keyof T> = Key extends string ? T[Key] extends Record<string, unknown> ? `${Key}.${FlattenObjectKeys<T[Key]>}` : `${Key}` : never;
interface InfiniteScrollerProps<Data extends Record<string, unknown>> {
  isLoading: boolean;
  direction: 'horizontal' | 'vertical';
  items: Data[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  uniqItemKey: FlattenObjectKeys<Data>;
  loadNext: () => void;
  total: number;
  placeholder: React.ComponentType;
  placeholderCount?: number;
  itemAsProps: string;
  onScroll?: InfiniteScrollProps['onScroll'];
  classes?: {
    item?: string;
    root?: string;
  };
  scrollerRef?: React.RefObject<Scroller>;
  itemProps?: Record<string, unknown>;
}
function InfiniteScroller<Data extends Record<string, unknown>>({
  scrollerRef,
  isLoading,
  direction,
  items,
  total,
  loadNext,
  component: ItemComponent,
  uniqItemKey,
  placeholder: PlaceholderComponent,
  placeholderCount = 3,
  itemAsProps,
  classes,
  onScroll,
  itemProps
}: InfiniteScrollerProps<Data>) {
  const isVerticalDirection = useMemo(() => direction === 'vertical', [direction]);
  const internalRef = useRef<HTMLDivElement>(null);
  const ref = useInfiniteScroll<HTMLDivElement>({
    // @ts-ignore
    next: loadNext,
    scrollThreshold: 0.65,
    windowScroll: false,
    onScroll,
    [isVerticalDirection ? 'rowCount' : 'columnCount']: items.length,
    hasMore: {
      [isVerticalDirection ? 'down' : 'right']: items.length < total
    }
  });
  const handleScrollNext = () => {
    const container = internalRef.current as HTMLDivElement;
    const containerScrollPos = isVerticalDirection ? container.scrollTop : container.scrollLeft;

    // Find the item that intersects with the midpoint
    const items = document.querySelectorAll(`.${styles.item}`);
    const item = Array.from(items).find(item => {
      const itemRect = item.getBoundingClientRect();
      const itemPos = isVerticalDirection ? itemRect.top : itemRect.left;
      return itemPos >= 0;
    });
    container.scrollTo({
      behavior: 'smooth',
      [isVerticalDirection ? 'top' : 'left']: containerScrollPos + (item?.clientWidth || 0)
    });
  };
  const handleScrollBack = () => {
    const container = internalRef.current as HTMLDivElement;
    const containerScrollPos = isVerticalDirection ? container.scrollTop : container.scrollLeft;

    // Find the item that intersects with the midpoint
    const items = document.querySelectorAll(`.${styles.item}`);
    const item = Array.from(items).find(item => {
      const itemRect = item.getBoundingClientRect();
      const itemPos = isVerticalDirection ? itemRect.top : itemRect.left;
      return itemPos >= 0;
    });
    container.scrollTo({
      behavior: 'smooth',
      [isVerticalDirection ? 'top' : 'left']: containerScrollPos - (item?.clientWidth || 0)
    });
  };
  useEffect(() => {
    if (scrollerRef) {
      updateRef(scrollerRef, {
        element: internalRef,
        scrollNext: handleScrollNext,
        scrollBack: handleScrollBack
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, internalRef]);
  return <div className={cx(styles.root, classes?.root, {
    [styles.vertical]: isVerticalDirection,
    [styles.loading]: isLoading
  })} ref={composeRefs(ref, internalRef)} data-sentry-component="InfiniteScroller" data-sentry-source-file="InfiniteScroller.tsx">
      {items.map((item, index) =>
    // @ts-ignore
    <div className={cx(styles.item, classes?.item)} key={get(item, uniqItemKey)}>
          <ItemComponent {...{
        [itemAsProps]: item
      }} {...itemProps} index={index} />
        </div>)}
      {isLoading && Array(placeholderCount).fill(0).map((_, index) =>
    // eslint-disable-next-line react/no-array-index-key
    <div className={cx(styles.item, classes?.item)} key={index}>
              <PlaceholderComponent {...itemProps} />
            </div>)}
    </div>;
}
export default InfiniteScroller;