'use client';

import React, { useEffect } from 'react';
import { useParams, useRouter } from 'next/navigation';
import cx from 'classnames';
import { TPost } from 'business/channel/repository/types.d';
import * as Post from 'components/PostContentParts';
import { ReactComponent as ShareIcon } from 'public/assets/icons/share.svg';
import styles from './SearchResult.module.scss';
interface PostProps {
  post: TPost & {
    channelId: string;
    highlight?: string;
  };
  onClick?(muid: string): void;
}
const SearchResult: React.FC<PostProps> = ({
  post,
  onClick
}) => {
  const params = useParams();
  const router = useRouter();
  const [buttonRef, setButtonRef] = React.useState<HTMLDivElement | null>(null);
  useEffect(() => {
    // prevent modal opening. Because link nested in button
    if (buttonRef) {
      buttonRef.querySelectorAll(`a`).forEach(el => {
        el.addEventListener('click', e => {
          e.stopPropagation();
        });
      });
    }
  }, [buttonRef]);
  const handleOpenPost = () => {
    onClick?.(post.id);
    router.push(`/channel/${post.channelId || params.id}/${post.id}`, {
      scroll: false
    });
  };
  return <Post.Root post={post} data-sentry-element="unknown" data-sentry-component="SearchResult" data-sentry-source-file="SearchResult.tsx">
      {({
      hasGallery,
      isRepost,
      isDeleted
    }) =>
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div ref={setButtonRef} onClick={handleOpenPost} className={cx(styles.root, {
      [styles.hasGallery]: hasGallery
    })}>
          <div className={cx(styles.meta, {
        [styles.withRepost]: isRepost
      })}>
            <Post.ChannelInfo avatarSize="24" className={styles.channelInfo} />
            {isDeleted ? <Post.DeletedInfo classes={{
          root: styles.deleteInfo,
          info: styles.deleteInfoInfo,
          date: styles.deleteInfoDate
        }} /> : <Post.DateTimeWithActions withoutActions typographyProps={{
          color: 'secondary'
        }} className={styles.dateTime} />}
            {isRepost && <div className={styles.repost}>
                <ShareIcon className={styles.repostIcon} />{' '}
                <Post.RepostInfo classes={{
            root: styles.repostInfo,
            button: styles.repostButton
          }} avatarProps={{
            size: '18'
          }} />
              </div>}
          </div>
          <div className={styles.content}>
            <Post.Audio classes={{
          root: styles.audio
        }} />
            <Post.TextContent lineClamp={2} shouldShowReadMore={false} />
            <Post.Documents withoutSize className={styles.documents} />
          </div>
          <div className={styles.gallery}>
            <Post.Gallery previewCount={3} classes={{
          root: styles.gallery,
          container: styles.galleryContainer,
          media: styles.media
        }} />
          </div>
        </div>}
    </Post.Root>;
};
export default SearchResult;