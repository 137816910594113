import uniqBy from 'lodash/uniqBy';

import { getChannelsCatalogue } from 'serverActions/catalogue/channels';

type TChannel = Awaited<ReturnType<typeof getChannelsCatalogue>>['channels']['0'];

export type THistory = {
  channels?: (
    | { keyword: string; type: 'keyword' }
    | {
        avatar: string;
        title: string;
        id: string;
        category: { title: string; id: string }[];
        isVerified: boolean | null;
        type: 'channel';
      }
  )[];
  posts?: ({ keyword: string; type: 'keyword' } | { id: string; type: 'post' })[];
};

type TPost = { postId: string };

export const getHistory = () => {
  return JSON.parse(localStorage.getItem('searchHistory') || '{}') as THistory;
};

const saveHistory = (history: THistory) => {
  localStorage.setItem('searchHistory', JSON.stringify(history));
};

const getUniqKey = (entity: Required<THistory>['channels' | 'posts']['0']) => {
  if (entity.type === 'keyword') {
    return entity.keyword;
  }

  return entity.id;
};

export function saveSearchHistory(entity: TChannel): void;
export function saveSearchHistory(entity: TPost): void;
export function saveSearchHistory(entity: string, subject: 'posts' | 'channels'): void;
export function saveSearchHistory(
  entity: TChannel | TPost | string,
  subject?: 'posts' | 'channels'
): void {
  const history = getHistory();
  const newHistory: THistory = { ...history };
  if (typeof newHistory.channels === 'undefined') {
    newHistory.channels = [];
  }

  if (typeof newHistory.posts === 'undefined') {
    newHistory.posts = [];
  }

  if (typeof entity === 'string' && subject) {
    newHistory[subject]?.unshift({ type: 'keyword', keyword: entity });
  }

  if (typeof entity === 'object' && typeof (entity as TPost).postId !== 'undefined') {
    newHistory.posts.unshift({
      type: 'post',
      id: (entity as TPost).postId,
    });
  }

  if (typeof entity === 'object' && typeof (entity as TChannel).id !== 'undefined') {
    const channelEntity = entity as TChannel;
    newHistory.channels.unshift({
      type: 'channel',
      avatar: channelEntity?.profileImage?.url || '',
      id: channelEntity.id,
      title: channelEntity.title,
      isVerified: channelEntity.isVerified,
      category: channelEntity?.category || [],
    });
  }

  saveHistory({
    ...history,
    channels: uniqBy(newHistory.channels, getUniqKey).slice(0, 5),
    posts: uniqBy(newHistory.posts, getUniqKey).slice(0, 5),
  });
}
