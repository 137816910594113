import React, { ComponentProps, useState } from 'react';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';
import Typography from 'components/Typography';
import { getHistory, THistory } from 'features/Search/history';
import PostSearchTemplate from 'features/PostTemplate/SearchResult';
import PostSearchTemplatePlaceholder from 'features/PostTemplate/SearchResult/Placeholder';
import { getPostsCatalogue } from 'serverActions/catalogue/posts';
import { Link } from 'utils/navigation';
import { ReactComponent as RecentIcon } from 'public/assets/icons/recent.svg';
import Button from 'components/Button';
import styles from './PostsHistory.module.scss';
interface PostsHistoryProps {
  search: string;
  category: string;
  views: string;
}
const PostsHistory: React.FC<PostsHistoryProps> = () => {
  const t = useTranslations();
  const [history, setHistory] = useState<THistory>(getHistory());
  const historyIsEmpty = !history?.posts?.length;
  const {
    data,
    isLoading
  } = useSWR(() => {
    const historyPosts = (history.posts?.filter(h => h.type === 'post') || []) as {
      id: string;
      type: 'post';
    }[];
    if (historyPosts.length) {
      const postsList = historyPosts.map(({
        id
      }) => id).join(',');
      return {
        entity: `search/posts/${postsList}`,
        messagesIn: postsList
      };
    }
    return null;
  }, getPostsCatalogue, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const handleClearHistory = () => {
    const history = JSON.parse(localStorage.getItem('searchHistory') || '{}') as THistory;
    localStorage.setItem('searchHistory', JSON.stringify({
      ...history,
      posts: []
    }));
    setHistory(getHistory());
  };
  return <div className={styles.root} data-sentry-component="PostsHistory" data-sentry-source-file="PostsHistory.tsx">
      <Typography component="p" variant="h10" className={styles.sectionTitle} data-sentry-element="Typography" data-sentry-source-file="PostsHistory.tsx">
        {t('Header.search.full.recent')}
        {!historyIsEmpty && <Button size="small" variant="secondary" onClick={handleClearHistory}>
            {t('Header.search.full.clear')}
          </Button>}
      </Typography>
      <div className={styles.history}>
        {history?.posts?.map(history => <div className={styles.item} key={history.type === 'keyword' ? history.keyword : history.id}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {history.type === 'keyword' ? <div key={history.keyword}>
                <Link href={{
            pathname: '/channels',
            query: {
              search: history.keyword
            }
          }} className={styles.keyword}>
                  <div className={styles.rounded}>
                    <RecentIcon />
                  </div>
                  <Typography variant="h9bk" color="primary">
                    {history.keyword}
                  </Typography>
                </Link>
              </div> : isLoading ? <PostSearchTemplatePlaceholder /> : <PostSearchTemplate post={data?.posts?.find(p => p.id === history.id) as ComponentProps<typeof PostSearchTemplate>['post']} />}
          </div>)}
      </div>
      {historyIsEmpty && <div className={styles.empty}>
          <Typography variant="h6sb">{t('Header.search.full.posts.empty')}</Typography>
        </div>}
    </div>;
};
export default PostsHistory;