import React, { useContext, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useTranslations } from 'next-intl';
import { Link } from 'utils/navigation';
import { getPostsCatalogue } from 'serverActions/catalogue/posts';
import PostSearchTemplate from 'features/PostTemplate/SearchResult';
import PostSearchTemplatePlaceholder from 'features/PostTemplate/SearchResult/Placeholder';
import { saveSearchHistory } from 'features/Search/history';
import InfiniteScroller from 'components/InfiniteScroller';
import Typography from 'components/Typography';
import useDebounce from 'hooks/useDebounce';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import styles from './PostsResults.module.scss';
interface PostsResultsProps {
  search: string;
  category: string;
  views: string;
}
const PostsResults: React.FC<PostsResultsProps> = ({
  search,
  category,
  views
}) => {
  const t = useTranslations();
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const debouncedSearch = useDebounce(search, 700);
  const {
    data,
    isValidating,
    setSize,
    size
  } = useSWRInfinite(index => ({
    page: (index + 1).toString(),
    search: debouncedSearch,
    location: countriesFilter,
    views,
    category
  }), args => getPostsCatalogue(args), {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const {
    posts,
    total
  } = useMemo(() => {
    if (data) {
      return {
        posts: data.flatMap(page => {
          return page ? page.posts.slice(0, 15) : [];
        }),
        total: data[0]?.total || 0
      };
    }
    return {
      posts: [],
      total: 0
    };
  }, [data]);
  const getNextPosts = async () => {
    if (posts.length < total && !isValidating) {
      return setSize(size + 1);
    }
    return undefined;
  };
  const handleSaveHistory = (postId: string) => {
    saveSearchHistory({
      postId
    });
  };
  return <div className={styles.root} data-sentry-component="PostsResults" data-sentry-source-file="PostsResults.tsx">
      <Typography variant="h10" data-sentry-element="Typography" data-sentry-source-file="PostsResults.tsx">{t('Header.search.full.posts.results.title')}</Typography>
      <InfiniteScroller isLoading={isValidating} direction="vertical" items={posts} component={PostSearchTemplate} uniqItemKey="id" loadNext={getNextPosts} total={total} placeholder={PostSearchTemplatePlaceholder} itemProps={{
      onClick: handleSaveHistory
    }} itemAsProps="post" classes={{
      root: styles.list,
      item: styles.item
    }} data-sentry-element="InfiniteScroller" data-sentry-source-file="PostsResults.tsx" />
      {!isValidating && total === 0 && <div className={styles.empty}>
          <Typography variant="h6sb">{t('Header.search.full.posts.results.empty')}</Typography>
        </div>}
      <Link href={{
      pathname: '/posts',
      query: {
        search,
        ...(views && {
          views
        }),
        ...(category && {
          category
        })
      }
    }} className={styles.keyword} data-sentry-element="Link" data-sentry-source-file="PostsResults.tsx">
        <div className={styles.rounded}>
          <SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="PostsResults.tsx" />
        </div>
        <Typography variant="h10" color="primary" data-sentry-element="Typography" data-sentry-source-file="PostsResults.tsx">
          “{search}”
        </Typography>
      </Link>
    </div>;
};
export default PostsResults;