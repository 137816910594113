import React from 'react';
import styles from './Placeholder.module.scss';
const Placeholder: React.FC = () => {
  return <div className={styles.root} data-sentry-component="Placeholder" data-sentry-source-file="Placeholder.tsx">
      <div className={styles.content}>
        <div className={styles.meta}>
          <div className={styles.channel} />
          <div className={styles.date} />
        </div>
        <div className={styles.text} />
        <div className={styles.text} />
      </div>
      <div>
        <div className={styles.gallery} />
      </div>
    </div>;
};
export default Placeholder;