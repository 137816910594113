import React, { useContext, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useTranslations } from 'next-intl';
import InfiniteScroller from 'components/InfiniteScroller';
import Button from 'components/Button';
import Typography from 'components/Typography';
import ChannelAvatar from 'components/Avatar/Channel';
import Tag from 'components/Tag';
import { Link } from 'utils/navigation';
import { saveSearchHistory } from 'features/Search/history';
import { getChannelsCatalogue } from 'serverActions/catalogue/channels';
import useDebounce from 'hooks/useDebounce';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import Placeholder from './Placeholder';
import styles from './Results.module.scss';
interface ResultsProps {
  search: string;
  category: string;
  subscribers: string;
}
type TChannel = Awaited<ReturnType<typeof getChannelsCatalogue>>['channels']['0'];
const ChannelCard: React.FC<{
  channel: TChannel;
  onClick: (channel: TChannel) => void;
}> = ({
  channel,
  onClick
}) => {
  const t = useTranslations();
  return <Link href={{
    pathname: '/channel/[id]',
    params: {
      id: channel.id
    }
  }} onClick={() => onClick(channel)} className={styles.channel} data-sentry-element="Link" data-sentry-component="ChannelCard" data-sentry-source-file="Results.tsx">
      <ChannelAvatar size="32" name={channel.title} src={channel?.profileImage?.url} alt={`Channel ${channel.title} avatar`} verified={!!channel.isVerified} data-sentry-element="ChannelAvatar" data-sentry-source-file="Results.tsx" />
      <Typography variant="h9bk" color="primary" data-sentry-element="Typography" data-sentry-source-file="Results.tsx">
        {channel.title}
      </Typography>
      <div>
        {(channel?.category || []).map(category =>
      // @ts-ignore
      <Tag key={category.id}>{t(`categories.${category.id}`)}</Tag>)}
      </div>
    </Link>;
};
const Results: React.FC<ResultsProps> = ({
  search,
  category,
  subscribers
}) => {
  const t = useTranslations();
  const {
    countriesFilter
  } = useContext(ConfigurationContext);
  const debouncedSearch = useDebounce(search, 700);
  const {
    data,
    isValidating,
    setSize,
    size
  } = useSWRInfinite(index => ({
    page: (index + 1).toString(),
    search: debouncedSearch,
    location: countriesFilter,
    members: subscribers,
    categories: category
  }), args => getChannelsCatalogue({
    metrics: [],
    searchParams: args
  }), {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  });
  const {
    channels,
    total
  } = useMemo(() => {
    if (data) {
      return {
        channels: data.flatMap(page => {
          return page ? page.channels : [];
        }),
        total: data[0]?.total || 0
      };
    }
    return {
      channels: [],
      total: 0
    };
  }, [data]);
  const loadNext = () => {
    if (!isValidating) {
      setSize(size + 1);
    }
  };
  const handleSaveChannelHistory = (channel: TChannel) => {
    saveSearchHistory(channel);
  };
  return <div className={styles.root} data-sentry-component="Results" data-sentry-source-file="Results.tsx">
      <Typography variant="h10" className={styles.title} component="h6" data-sentry-element="Typography" data-sentry-source-file="Results.tsx">
        {total} {t('Header.search.full.results')}
      </Typography>
      <InfiniteScroller isLoading={isValidating} direction="vertical" items={channels} component={ChannelCard} uniqItemKey="id" loadNext={loadNext} total={total} placeholder={Placeholder} itemAsProps="channel" classes={{
      root: styles.list,
      item: styles.item
    }} itemProps={{
      onClick: handleSaveChannelHistory
    }} data-sentry-element="InfiniteScroller" data-sentry-source-file="Results.tsx" />
      <Link href={{
      pathname: '/channels',
      query: {
        search,
        ...(subscribers && {
          members: subscribers
        }),
        ...(category && {
          categories: category
        })
      }
    }} className={styles.keyword} data-sentry-element="Link" data-sentry-source-file="Results.tsx">
        <div className={styles.rounded}>
          <SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="Results.tsx" />
        </div>
        <Typography variant="h10" color="primary" data-sentry-element="Typography" data-sentry-source-file="Results.tsx">
          “{search}”
        </Typography>
      </Link>
      <div className={styles.addChannel}>
        <Typography variant="paragraph10bk" data-sentry-element="Typography" data-sentry-source-file="Results.tsx">{t('Header.search.full.add.message')}</Typography>
        <Button variant="secondary" component={Link} href="/channel/add" data-sentry-element="Button" data-sentry-source-file="Results.tsx">
          {t('Header.search.full.add.submit')}
        </Button>
      </div>
    </div>;
};
export default Results;